import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from '@blackbox-vision/ra-language-spanish';
import englishMessages from 'ra-language-english';
import spanishCustomMessages from './translations/es';
import englishCustomMessages from './translations/en';
import i18n from './i18n';

const messages = {
  es: {
    ...spanishMessages,
    ...spanishCustomMessages,
  },
  en: {
    ...englishMessages,
    ...englishCustomMessages,
  },
};
console.log(i18n.language);
const i18nProvider = polyglotI18nProvider(
  locale => messages[locale],
  i18n.language || 'en', // idioma por defecto
  { allowMissing: true }
);

export default i18nProvider;

