import { SimpleForm } from 'react-admin';
import { styled } from '@mui/material/styles';
import {Box } from '@mui/material';

const CenteredContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  });

const CustomSimpleForm = styled((props) => (
    <CenteredContainer>
        <SimpleForm {...props} />
    </CenteredContainer>
))(({ theme }) => ({
    border: '2px solid #f5f5f5',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
}));

export default CustomSimpleForm;
