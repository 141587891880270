import React from 'react';
import { List, TextField, DateField, NumberField, EditButton, useTranslate, usePermissions } from 'react-admin';
import CustomDataGrid from '../CustomDatagrid';

const InstallationList = (props) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    return (
        <List {...props} perPage={25}>
            <CustomDataGrid>
                <TextField 
                    source='group_name' 
                    label={translate("resources.installations.fields.group_name")}
                />
                <TextField 
                    source='installation_name' 
                    label={translate("resources.installations.fields.installation_name")}
                />
                
                {permissions === 0 && (   
                    <NumberField 
                        source='max_devices' 
                        label={translate("resources.installations.fields.max_devices")}
                    />
                )}

                {permissions === 0 && (   
                    <DateField 
                            source='license_expiration' 
                            label={translate("resources.installations.fields.license_expiration")}
                    />
                )}

                <EditButton label =""/>
            </CustomDataGrid>
        </List>
    );
};

export default InstallationList;
