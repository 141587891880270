import React, { useEffect } from 'react';
import { Layout, useDataProvider } from 'react-admin';
import { Box, CssBaseline, ThemeProvider, useMediaQuery, Button } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack'; 
import CustomAppBar from '../components/CustomAppBar';
import CustomMenu from '../components/pages/CustomMenu';
import ecoModernTheme from '../styles/Theme';
import MyUserMenu from '../components/pages/MyUserMenu';
import { requestPermission, onMessageListener } from '../notifications/firebaseConfig';

const NotificationListener = () => {
  const dataProvider = useDataProvider();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar(); 

  useEffect(() => {
    const saveToken = async (token) => {
      try {
        await dataProvider.savePushToken({ data: { pushToken: token } });
        console.log('Push token saved successfully');
      } catch (error) {
        console.error('Failed to save push token:', error);
      }
    };

    requestPermission(saveToken);

    // Escucha todos los mensajes en primer plano
    onMessageListener((payload) => {
      console.log('Received foreground message: ', payload);
      enqueueSnackbar(`${payload.notification.title}: ${payload.notification.body}`, {
        variant: 'info',
        autoHideDuration: null, 
        action: (key) => (
          <Button onClick={() => closeSnackbar(key)} style={{ color: '#fff' }}>
            Cerrar
          </Button>
        ),
      });
    });

  }, [dataProvider, enqueueSnackbar, closeSnackbar]);

  return null; 
};

const CustomLayout = (props) => {
  const isSmallScreen = useMediaQuery(ecoModernTheme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={ecoModernTheme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CssBaseline />
        <NotificationListener /> 
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            py: 0,
            px: { xs: 4, sm: 6 },
            mt: { xs: 14, sm: 6 },
            width: '100%',       
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              bgcolor: 'background.paper',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 0,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '0px',
                background: `linear-gradient(90deg, ${ecoModernTheme.palette.primary.main} 0%, ${ecoModernTheme.palette.secondary.main} 100%)`,
              },
            }}
          >
            <Layout
              {...props}
              appBar={CustomAppBar}
              menu={CustomMenu}
              userMenu={<MyUserMenu />}
              sx={{
                '& .RaLayout-content': {
                  padding: isSmallScreen ? 2 : 3,
                  overflowX: 'auto', 
                  width: '100%', 
                },
              }}
            />
          </Box>
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default CustomLayout;












/*import React, { useEffect } from 'react';
import { Layout, useDataProvider } from 'react-admin';
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import CustomAppBar from '../components/CustomAppBar';
import CustomMenu from '../components/pages/CustomMenu';
import ecoModernTheme from '../styles/Theme';
import MyUserMenu from '../components/pages/MyUserMenu';
import { requestPermission } from '../notifications/firebaseConfig';

const CustomLayout = (props) => {
  const isSmallScreen = useMediaQuery(ecoModernTheme.breakpoints.down('md'));
  const dataProvider = useDataProvider();

  useEffect(() => {
    const saveToken = async (token) => {
      try {
        await dataProvider.savePushToken({ data: { pushToken: token } });
        console.log('Push token saved successfully');
      } catch (error) {
        console.error('Failed to save push token:', error);
      }
    };

    requestPermission(saveToken);

    onMessageListener()
    .then((payload) => {
      console.log('Received foreground message: ', payload);
      // Aquí puedes manejar la visualización de la notificación en la UI
    })
    .catch((err) => console.error('Error receiving foreground message: ', err));


  }, [dataProvider]);

  return (
    <ThemeProvider theme={ecoModernTheme}>
      <CssBaseline />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            py: 0,
            px: { xs: 4, sm: 6 },
            mt: { xs: 14, sm: 6 },
            width: '100%',       
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              bgcolor: 'background.paper',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 0,
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '0px',
                background: `linear-gradient(90deg, ${ecoModernTheme.palette.primary.main} 0%, ${ecoModernTheme.palette.secondary.main} 100%)`,
              },
            }}
          >
            <Layout
              {...props}
              appBar={CustomAppBar}
              menu={CustomMenu}
              userMenu={<MyUserMenu />}
              sx={{
                
                '& .RaLayout-content': {
                  padding: isSmallScreen ? 2 : 3,
                  overflowX: 'auto', 
                  width: '100%', 
                },
              }}
            />
          </Box>
        </Box>
   
    </ThemeProvider>
  );
};

export default CustomLayout;*/
