import React from 'react';
import { useRecordContext, useDelete, Button } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';

const CustomDeleteButton = ({ resource, onSuccess, onError }) => {
    const record = useRecordContext(); 

    const [deleteOne, { isLoading }] = useDelete(
        resource, 
        { id: record.id, previousData: record },
        { onSuccess, onError,}
    );

    return (
        <Button
            label=""
            onClick={() => deleteOne()}
            disabled={isLoading}
            startIcon={<DeleteIcon />}
             color="error"
            
        />
    );
};

export default CustomDeleteButton;

