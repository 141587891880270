import React from 'react';
import { Grid } from '@mui/material';

const InputGrid = ({ inputs, buttons }) => (
    <Grid container spacing={3}>
        {inputs.map((input, index) => (
            <Grid item xs={12} md={6} key={index}>
                {input}
            </Grid>
        ))}
        {buttons && (
            <Grid item xs={12}>
                <Grid container justifyContent="flex-start" spacing={1}>
                    {buttons.map((button, index) => (
                        <Grid item key={index}>
                            {button}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        )}
    </Grid>
);

export default InputGrid;
