import React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import { Box, Toolbar, useTheme } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher';
import ecoModernTheme from '../styles/Theme';
import MyUserMenu from './pages/MyUserMenu';

const CustomAppBar = () => {
    const theme = useTheme();

    return (
        <AppBar userMenu={<MyUserMenu />} position="fixed"
            sx={{
                width: '100%',
                //marginLeft: '10%',
               // marginRight: '10%',
               paddingLeft: '15%',
               paddingRight: '15%',
                
                //background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                background: ecoModernTheme.palette.primary.dark,
                backgroundColor: 'transparent',
                //color: theme.palette.primary.contrastText,
                color: theme.palette.primary.dark,  
                boxShadow: 'none',
                borderRadius: '0px',
                borderBottom: `2px solid ${theme.palette.primary.light}`,
            }}
        >
            <Toolbar 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    width: '100%', 
                    padding: theme.spacing(0, 2),
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TitlePortal/>
                    
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                
                    <LanguageSwitcher />
                
                </Box>
                
            </Toolbar>

        </AppBar>
        
    );
};

export default CustomAppBar;
/*
<AppBar
        sx={{
            maxWidth:'98%',
            marginLeft:'1%',
            marginRight:'1%',
            marginTop:'5px',
            boxShadow: theme.shadows[2],
            borderRadius: 2,
            background: 'linear-gradient(135deg, #f3f9f3 0%, #e9ecef 100%)', 
            backgroundColor:'#e9ecef',
            color: theme.palette.primary.contrastText,
        }}
        ></AppBar>
        
        
        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                        {userName}
                    </Typography>*/