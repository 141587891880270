import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import { Box, useTheme } from '@mui/material';
import logo from '../../assets/images/logo512.png';
import { CustomDeviceIcon, CustomEventIcon, CustomGroupIcon, CustomInstallationIcon, CustomUserIcon } from './CustomIcons';


const CustomMenu = () => {
    const t = useTranslate();
    const theme = useTheme();
    const roleLevel = parseInt(localStorage.getItem('level'), 10);

    

    return (
        <Box
            sx={{
                height: '100%',
                '& .RaMenu-root': {
                    backgroundColor: 'transparent',
                    
                    boxShadow: 'none',
                    '& .RaMenu-item': {
                        color: theme.palette.primary.contrastText,
                        '&.RaMenu-active': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                    },
                },
            }}
        >
            <Box 
                component="img" 
                src={logo} 
                alt="Logo" 
                sx={{ 
                    marginLeft: '56px', 
                    padding: '20px', 
                    width: '120px' 
                }} 
            />
            <Menu>
                <Menu.DashboardItem primaryText={t('pages.dashboard.menu')} />
                {roleLevel <= 2 && (
                    <Menu.Item to="/users" primaryText={t('resources.users.name')} leftIcon={<CustomUserIcon />} />
                )}
                {roleLevel < 2 && (
                    <Menu.Item to="/group" primaryText={t('resources.group.name')} leftIcon={<CustomGroupIcon />} />
                )}
                  {roleLevel < 2 && (
                <Menu.Item to="/installations" primaryText={t('resources.installations.name')} leftIcon={<CustomInstallationIcon />} />
                )}
                <Menu.Item to="/devices" primaryText={t('resources.devices.name')} leftIcon={<CustomDeviceIcon />} />
                <Menu.Item to="/events" primaryText={t('resources.events.name')} leftIcon={<CustomEventIcon />} />
            </Menu>
        </Box>
    );
};

export default CustomMenu;





