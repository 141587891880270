import React, { useEffect, useState } from 'react';
import { Edit, useTranslate, useDataProvider, useGetOne } from 'react-admin';
import { Typography, Divider, Box, Chip } from '@mui/material';
import { useParams } from 'react-router-dom';
import CustomSimpleForm from '../pages/CustomSimpleForm';
import InputGrid from '../pages/InputGrid';
import { CustomTextInput } from '../pages/CustomInput';
import { CustomGroupIcon } from '../pages/CustomIcons';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';

const GroupEdit = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const { id } = useParams();  // Obtiene el ID de la URL
    const [installations, setInstallations] = useState([]);
    const [loading, setLoading] = useState(true);

    const { data: record } = useGetOne('group', { id });

    useEffect(() => {
        if (record && record.id) {
            dataProvider.getList('installations', {
                filter: { group_id: record.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'installation_name', order: 'ASC' },
            }).then(({ data }) => {
                setInstallations(data);
                setLoading(false);
            }).catch((error) => {
                console.error("Error loading installations:", error);
                setLoading(false);
            });
        }
    }, [record, dataProvider]);

    const inputs = [
        <CustomTextInput
            source="group_name"
            label={translate("resources.group.fields.group_name")}
            icon={CustomGroupIcon}
            fullWidth
        />,
        <CustomTextInput
            source="owner_info"
            label={translate("resources.group.fields.owner_info")}
            icon={PersonIcon}
            fullWidth
        />,
        <CustomTextInput
            source="description"
            label={translate("resources.group.fields.description")}
            icon={DescriptionIcon}
            fullWidth
        />,
    ];

    return (
        <Edit {...props} title={translate("resources.group.edit_title")} mutationMode="pessimistic">
            <CustomSimpleForm>
                <InputGrid inputs={inputs} />

                <Divider sx={{ margin: '20px 0' }} />
                
                        <Typography variant="h6" gutterBottom>
                            {translate("resources.group.associated_installations")}
                        </Typography>
                        {loading ? (
                            <Typography variant="body2">{translate("resources.group.actions.installations_loading")}</Typography>
                        ) : installations.length > 0 ? (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {installations.map((installation) => (
                                    <Chip
                                        key={installation.id}
                                        label={installation.installation_name}
                                        color="primary"
                                        variant="outlined"
                                    />
                                ))}
                            </Box>
                        ) : (
                            <Typography variant="body2">
                                {translate("resources.group.actions.installations_empty")}
                            </Typography>
                        )}
                   
            </CustomSimpleForm>
        </Edit>
    );
};

export default GroupEdit;


