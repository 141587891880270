import React, { useState } from 'react';
import { useTranslate, Edit, useNotify, useRefresh, useRedirect, useGetList, useGetOne } from 'react-admin';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import ApiKeyDialog from './ApiKeyDialog';
import dataProvider from '../../data/dataProvider';
import { useParams } from 'react-router-dom';
import CustomSimpleForm from '../pages/CustomSimpleForm';
import { CustomDateInput, CustomNumberInput, CustomSelectInput, CustomTextInput, CustomButton } from '../pages/CustomInput';
import InputGrid from '../pages/InputGrid';
import { CustomInstallationIcon, CustomDeviceIcon, CustomGroupIcon, CustomEventIcon } from '../pages/CustomIcons';

const InstallationEdit = (props) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { data: group, isLoading: groupsLoading } = useGetList('group');
    const { id } = useParams();
    const { data: record } = useGetOne('installations', { id });
    const [newApiKey, setNewApiKey] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleConfirmChangeApiKey = async () => {
        try {
            const { data } = await dataProvider.changeApiKey('installations', id);
            setNewApiKey(data.apiKey);
            setConfirmDialogOpen(false);
        } catch (error) {
            console.error("Error changing API Key:", error);
            notify(translate('api_key_copy_failed'), { type: 'error' });
        }
    };

    const handleCloseDialog = () => {
        setNewApiKey(null);
        refresh();
        redirect(`/installations/${id}`);
    };

    // Define los inputs en un array
    const inputs = [
        <CustomTextInput
            source="installation_name"
            fullWidth
            label={translate("resources.installations.fields.installation_name")}
            icon={CustomInstallationIcon}
        />,
        <CustomNumberInput
            source="max_devices"
            fullWidth
            label={translate("resources.installations.fields.max_devices")}
            icon={CustomDeviceIcon}
        />,
        !groupsLoading && (
            <CustomSelectInput
                source="group_id"
                choices={group}
                optionText="group_name"
                optionValue="id"
                fullWidth
                label={translate("resources.installations.fields.group_name")}
                icon={CustomGroupIcon}
            />
        ),
        <CustomDateInput
            source="license_expiration"
            fullWidth
            label={translate("resources.installations.fields.license_expiration")}
            icon={CustomEventIcon}
        />,
    ];

    return (
        <Edit {...props} record={record} title={translate("resources.installations.edit_page_title")}>
            <CustomSimpleForm>
                <InputGrid inputs={inputs} />
                <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                    <CustomButton onClick={() => setConfirmDialogOpen(true)}>
                        {translate("resources.installations.actions.change_api_key")}
                    </CustomButton>
                </Box>
            </CustomSimpleForm>

            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                <DialogTitle>{translate("resources.installations.actions.confirm_change_api_key_title")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{translate("resources.installations.actions.confirm_change_api_key_text")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={() => setConfirmDialogOpen(false)}>
                        {translate("resources.installations.actions.cancel")}
                    </CustomButton>
                    <CustomButton onClick={handleConfirmChangeApiKey} autoFocus>
                        {translate("resources.installations.actions.confirm")}
                    </CustomButton>
                </DialogActions>
            </Dialog>

            <ApiKeyDialog apiKey={newApiKey} open={!!newApiKey} onClose={handleCloseDialog} />
        </Edit>
    );
};

export default InstallationEdit;

