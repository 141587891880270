import React from 'react';
import { Filter, ReferenceInput, SelectInput, useTranslate } from 'react-admin';

const DeviceFilter = (props) => {
    const translate = useTranslate();

    
    
    const handleChange = (event) => {
        console.log('Selected installation_id:', event.target.value);
    };
    return(
        <Filter {...props} >
            <ReferenceInput
                
                source="installation_id"
                reference="installations"
                sort={{ field: 'installation_name', order: 'ASC' }}
                alwaysOn
            >
                <SelectInput 
                    optionText="installation_name" 
                    emptyText={translate("resources.installations.all")}
                    label={translate("resources.installations.name")}
                   
                />
            </ReferenceInput>
        </Filter>
    );
};

export default DeviceFilter;