import React, { useEffect, useState } from 'react';
import { Filter, ReferenceInput, SelectInput, useTranslate, useDataProvider, DateTimeInput } from 'react-admin';

const ExtendedFilter = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [installationId, setInstallationId] = useState(null);
    const [deviceChoices, setDeviceChoices] = useState([]);

    useEffect(() => {
        if (installationId) {
            dataProvider.getList('devices', {
                filter: { installation_id: installationId },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'device_name', order: 'ASC' },
            }).then(({ data }) => {
                setDeviceChoices(data.map(device => ({ id: device.id, name: device.device_name })));
            });
        } else {
            setDeviceChoices([]);
        }
    }, [installationId, dataProvider]);

    
    const handleInstallationChange = (event) => {
        setInstallationId(event.target.value);
    };

    const now = new Date();
    const oneDayAgo = new Date();
    oneDayAgo.setDate(now.getDate() - 1);

    const toLocalISOString = (date) => {
        return date.toISOString().slice(0, 23);
    };

    return (
        <Filter {...props} >
            <ReferenceInput
                
                source="installation_id"
                reference="installations"
                sort={{ field: 'installation_name', order: 'ASC' }}
                alwaysOn
                >
                <SelectInput 
                label={translate("resources.installations.name")}
                    optionText="installation_name" 
                    emptyText={translate("resources.installations.all")}
                    onChange={handleInstallationChange}
                />
            </ReferenceInput>
            <SelectInput 
                label={translate("resources.events.fields.device_name")}
                source="device_id"
                choices={deviceChoices}
                emptyText={translate("resources.devices.all")}
                alwaysOn
            />
            <DateTimeInput 
                label={translate("resources.events.fields.timestamp_from")}
                source="timestamp_gte"
                defaultValue={toLocalISOString(oneDayAgo)}
                
            />
            <DateTimeInput 
                label={translate("resources.events.fields.timestamp_to")}
                source="timestamp_lte"
                defaultValue={toLocalISOString(now)}
                
            />
        </Filter>
    );
};

export default ExtendedFilter;
