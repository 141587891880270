const apiUrl = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${apiUrl}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, level, username, expires_in }) => {
                const expiration = new Date().getTime() + expires_in * 1000; 
                localStorage.setItem('token', token);
                localStorage.setItem('level', level); 
                localStorage.setItem('username', username); 
                localStorage.setItem('token_expiration', expiration);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('level'); 
        localStorage.removeItem('username'); 
        localStorage.removeItem('token_expiration');
        return Promise.resolve();
    },
    checkAuth: () => {
        const token = localStorage.getItem('token');
        const expiration = localStorage.getItem('token_expiration');
        if (!token || !expiration || new Date().getTime() > expiration) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('level'); 
            localStorage.removeItem('username'); 
            localStorage.removeItem('token_expiration');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const level = localStorage.getItem('level');
        return Promise.resolve(level ? parseInt(level) : null);
    },
};

export default authProvider;

