import React, { useState } from 'react';
import { UserMenu, useLogout, useTranslate } from 'react-admin';
import { Typography, Box, Avatar } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/Person';
import { CustomButton } from './CustomInput';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChangePasswordDialog from '../UserComponents/ChangePasswordDialog';
import { CustomPasswordIcon } from './CustomIcons';



const MyUserMenu = props => {
    const translate = useTranslate();
    const userName = localStorage.getItem('username') || 'Guest';
    const logout = useLogout();
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
   
    const handlePasswordDialogOpen = () => {
        setPasswordDialogOpen(true);
    };
    
    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
    };

    return (
        <UserMenu {...props}>
        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
            <Avatar >
                <EmojiEmotionsIcon fontSize='large'/>
            </Avatar>
            <Typography variant="h6" style={{ marginTop: 16 }}>
                {userName}
            </Typography>

            <CustomButton
                startIcon={<CustomPasswordIcon />}
                style={{ marginTop: 16, width:'180px' }}
                onClick={handlePasswordDialogOpen}
                label = {translate("resources.users.actions.change_password")}
                >
                
            </CustomButton>
            <CustomButton
                startIcon={<ExitToAppIcon />}
                style={{ marginTop: 8, width: '180px' }}
                onClick={() => logout()}
                label = "Logout"
            >
              
            </CustomButton>
        </Box>
        <ChangePasswordDialog 
            open={passwordDialogOpen} 
            onClose={handlePasswordDialogClose} 
            userId={localStorage.getItem('userId')}
        />
        {props.children}
    </UserMenu>
    );
};

export default MyUserMenu;