import React, { useState } from 'react';
import { useSetLocale, useLocale } from 'react-admin';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const LanguageSwitcher = () => {
    const setLocale = useSetLocale();
    const locale = useLocale();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (lng) => {
        if (lng) {
            setLocale(lng);
            window.dispatchEvent(new Event('languageChanged'));
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                    fontSize: '1rem', // Ajusta este valor según necesites
                    fontWeight: 'normal',
                    textTransform: 'none',
                }}
            >
                {locale === 'en' ? 'EN' : 'ES'}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(null)}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => handleClose('en')} selected={locale === 'en'}>
                    <Typography variant="body1">English</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleClose('es')} selected={locale === 'es'}>
                    <Typography variant="body1">Español</Typography>
                </MenuItem>
            </Menu>
        </>
    );
};

export default LanguageSwitcher;


/*import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (lng) => {
        if (lng) {
            i18n.changeLanguage(lng);
            window.dispatchEvent(new Event('languageChanged'));
        }
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                {i18n.language === 'en' ? 'English' : 'Español'}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => handleClose(null)}
            >
                <MenuItem onClick={() => handleClose('en')}>English</MenuItem>
                <MenuItem onClick={() => handleClose('es')}>Español</MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageSwitcher;*/


