import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom'; 
import NotAuthorized from './components/NotAuthorized';
import { ThemeProvider } from '@mui/material/styles';
import authProvider from './data/authProvider';
import CustomLayout from './layouts/CustomLayout';
import Dashboard from './components/pages/Dashboard';
import UserList from './components/UserComponents/UserList';
import UserEdit from './components/UserComponents/UserEdit';
import UserCreate from './components/UserComponents/UserCreate';
import GroupCreate from './components/Group/GroupCreate.js';
import GroupList from './components/Group/GroupList';
import GroupEdit from './components/Group/GroupEdit';
import InstallationCreate from './components/InstallationComponents/InstallationCreate.js';
import InstallationEdit from './components/InstallationComponents/InstallationEdit';
import InstallationList from './components/InstallationComponents/InstallationList';
import i18nProvider from './i18nProvider';
import MyLoginPage from './components/pages/MyLoginPage.js';
import ecoModernTheme from './styles/Theme.js';
import './styles/App.css';
import EventList from './components/EventsComponents/EventList.js';
import DeviceList from './components/DeviceComponents/DeviceList.js';
import dataProvider from './data/dataProvider.js';

const App = () => (
    <ThemeProvider theme={ecoModernTheme}>
        
            <Admin
                layout={CustomLayout}
                loginPage={MyLoginPage}
                theme={ecoModernTheme}
                dataProvider={dataProvider}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                dashboard={Dashboard}
                customRoutes={[
                    <Route
                        key="not-authorized"
                        path="/not-authorized"
                        component={NotAuthorized}
                        noLayout
                    />, 
                ]}
            >
                {permissions => [
                    <Resource key="users" name="users" list={permissions <= 2 ? UserList : null} create={permissions <= 2 ? UserCreate : null} edit={permissions <= 2 ? UserEdit : null} />,
                    <Resource key="group" name="group" list={permissions <= 1 ? GroupList : null} edit={permissions <= 1 ? GroupEdit : null} create={permissions <= 1 ? GroupCreate : null}/>,
                    <Resource key="installations" name="installations" list={permissions <= 2 ? InstallationList : null} edit={permissions <= 2 ? InstallationEdit : null} create = {permissions === 0 ? InstallationCreate : null} />,
                    <Resource key="devices" name="devices" list={DeviceList} />,
                    <Resource key="events" name="events" list={EventList} />
                ]}
            </Admin>
    </ThemeProvider>
);

export default App;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker succesffuly registered:', registration);
      })
      .catch((error) => {
        console.error('Error registering the Service Worker:', error);
      });
  }





