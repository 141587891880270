import * as React from "react";
import { Create, SimpleForm, TextInput, required } from 'react-admin';

const GroupCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="group_name" label="Group Name" validate={required()} />
            <TextInput source="owner_info" label="Owner Info" />
            <TextInput source="description" label="Description" />
        </SimpleForm>
    </Create>
);

export default GroupCreate;
