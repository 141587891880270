import React, { useState } from 'react';
import { useLogin, useNotify, useTranslate } from 'react-admin';
import { Card, CardContent, Box, TextField, Button, Typography, InputAdornment, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from '../../assets/images/logo512.png';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoginCard = styled(Card)(({ theme }) => ({
  width: 420,
  borderRadius: theme.shape.borderRadius * 3,
  overflow: 'hidden',
  display: 'flex',
}));

const LogoSection = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.dark,
  width: '45%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const FormSection = styled(CardContent)(({ theme }) => ({
  width: '55%',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1),
  fontWeight: 'bold',
  textTransform: 'none',
}));

const MyLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password }).catch(() => notify('Invalid username or password'));
  };

  return (
    <BackgroundBox>
      
      <LoginCard elevation={10}>
        <LogoSection>
            <img src={logo} alt="Logo" style={{ maxWidth: '60%', height: 'auto' }} />
        </LogoSection>
        <FormSection>
        
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: '10%' }}>
            <StyledTextField
              name="username"
              placeholder={translate('ra.auth.username')}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              name="password"
              type="password"
              placeholder={translate('ra.auth.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <StyledButton
              variant="contained"
              type="submit"
              color="primary"
              
              size="large"
            >
              {translate('ra.auth.sign_in')}
            </StyledButton>
          </form>
          <Box mt={2} textAlign="center">
            <Link href="#" underline="hover">
              Forgot password
            </Link>
          </Box>
        </FormSection>
      </LoginCard>
    </BackgroundBox>
  );
};

export default MyLoginPage;



/*import React, { useState } from 'react';
import { useLogin, useNotify } from 'react-admin';
import { Card, CardContent, Box, TextField, Button, Typography, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from '../assets/images/logo512.png';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const LoginCard = styled(Card)(({ theme }) => ({
  width: 320,
  borderRadius: theme.shape.borderRadius * 3,
  overflow: 'hidden',
  display: 'flex',
}));

const LogoSection = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.dark,
  width: '40%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const FormSection = styled(CardContent)(({ theme }) => ({
  width: '60%',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1),
  fontWeight: 'bold',
  textTransform: 'none',
}));

const MyLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password }).catch(() => notify('Invalid username or password'));
  };

  return (
    <BackgroundBox>
      <LoginCard elevation={10}>
        <LogoSection>
          {logo ? (
            <img src={logo} alt="Logo" style={{ maxWidth: '80%', height: 'auto' }} />
          ) : (
            <LightbulbIcon sx={{ fontSize: 50, color: '#ffffff' }} />
          )}
        </LogoSection>
        <FormSection>
          <Typography variant="h5" component="h1" gutterBottom fontWeight="bold">
            Login
          </Typography>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <StyledTextField
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              name="password"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <StyledButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              size="large"
            >
              Sign In
            </StyledButton>
          </form>
        </FormSection>
      </LoginCard>
    </BackgroundBox>
  );
};

export default MyLoginPage;*/