import { TextInput, NumberInput, DateInput, SelectInput, Button } from 'react-admin';
import { styled, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';

const baseStyles = ({ theme }) => ({
    '& .MuiFilledInput-root': {
        background: 'transparent',
        border: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRadius: 0,
        height: '56px',
        '&:hover': {
            background: 'transparent',
        },
        '&.Mui-focused': {
            background: 'transparent',
        },
    },
    '& .MuiInputBase-input': {
        padding: '16.5px 14px',
    },
    '& .MuiSelect-select': {
        padding: '16.5px 14px',
    },
});

const withIcon = (Component) => ({ icon: IconComponent, ...props }) => {
    const theme = useTheme();
    return (
        <Component
            {...props}
            InputProps={{
                startAdornment: IconComponent ? (
                    <InputAdornment position="start">
                        <IconComponent style={{ color: theme.palette.primary.main }} />
                    </InputAdornment>
                ) : null,
            }}
        />
    );
};

const CustomTextInput = styled(withIcon(TextInput))(({ theme }) => baseStyles({ theme }));
const CustomNumberInput = styled(withIcon(NumberInput))(({ theme }) => baseStyles({ theme }));
const CustomDateInput = styled(withIcon(DateInput))(({ theme }) => baseStyles({ theme }));
const CustomSelectInput = styled(withIcon(SelectInput))(({ theme }) => baseStyles({ theme }));

const CustomButton = styled(Button)(({ theme }) => ({
    padding: "8px",
    variant: "contained",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

export { CustomTextInput, CustomNumberInput, CustomDateInput, CustomSelectInput, CustomButton };

