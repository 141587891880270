/*import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
    '& .RaDatagrid-headerCell': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        fontWeight: 550,
        padding: '4px 6px',
        fontSize: '1rem',
        textAlign: 'left',
    },
    '& .RaDatagrid-row': {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.grey[50],
        },
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        },
    },
    '& .RaDatagrid-rowCell': {
        padding: '0px 8px',
        fontSize: '1rem',
        height: '24px',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '& .column-action': {
        textAlign: 'center',
    },
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 5,
}));

const CustomDataGrid = ({ columns, rows, getRowId, ...props }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                maxWidth: '1500px',
                margin: '0 auto',
                padding: '10px',
            }}
        >
            <StyledDatagrid
                {...props}
                rows={rows}
                rowClick="edit"
                bulkActionButtons={false}
                size="small"
                sx={{
                    width: '100%',
                    '@media (max-width: 1500px)': {
                        width: '100%',
                        minWidth: 'auto',
                    },
                }}
            >
                {columns.map((column) => (
                    column.render ? (
                        <TextField
                            key={column.field}
                            source={column.field}
                            label={column.headerName}
                            sortable={column.sortable !== false}
                        >
                            {column.render}
                        </TextField>
                    ) : (
                        <TextField
                            key={column.field}
                            source={column.field}
                            label={column.headerName}
                            sortable={column.sortable !== false}
                        />
                    )
                ))}
            </StyledDatagrid>
        </Box>
    );
};

export default CustomDataGrid;*/





import React from 'react';
import { Datagrid } from 'react-admin';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
  '& .RaDatagrid-headerCell': {
    backgroundColor: theme.palette.primary.light, 
    color: theme.palette.primary.contrastText, 
    fontWeight: 550,
    padding: '6px 6px',
    fontSize: '1rem',
    textAlign: 'center',
  },
  '& .RaDatagrid-row': {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[50],
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  '& .RaDatagrid-rowCell': {
    padding: '4px 8px',
    fontSize: '1rem',
    height: '32px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .column-action': {
    textAlign: 'center',
  },
}));
const ScrollBox = styled(Container)({
  overflowX: 'auto',
  maxwidth: '100%',
});

const CustomDataGrid = (props) => {
  return (
    <ScrollBox>
     
        <StyledDatagrid
          {...props}
          bulkActionButtons={false}
          rowClick="edit"
          size="small"
        >
          {React.Children.map(props.children, (field) => 
            React.cloneElement(field, {
              label: field.props.label,
            })
          )}
        </StyledDatagrid>
    
    </ScrollBox>
  );
};



export default CustomDataGrid;
