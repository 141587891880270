import { fetchUtils } from 'react-admin';
import queryString from 'query-string';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (url.endsWith('/login')) {
        options.headers.set('x-api-key', process.env.REACT_APP_API_KEY);
    }
    else
    {
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filters = { ...params.filter };

        if (filters.timestamp_gte) {
            filters.timestamp_gte = new Date(filters.timestamp_gte).toISOString().slice(0, -1);
        }
        if (filters.timestamp_lte) {
            filters.timestamp_lte = new Date(filters.timestamp_lte).toISOString().slice(0, -1);
        }

        const query = {
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            ...params.filter,
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ 
            data: json.data,
            total: json.total,
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...json },   
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    
    changeApiKey: (resource, id) =>
        httpClient(`${apiUrl}/${resource}/${id}/changeApiKey`, {
            method: 'POST',
        }).then(({ json }) => ({ data: json })),

    changePassword: (userId, newPassword) =>
        httpClient(`${apiUrl}/users/${userId}/changePassword`, {
            method: 'POST',
            body: JSON.stringify({ newPassword }), 
            headers: new Headers({ 'Content-Type': 'application/json' }), 
        }).then(({ json }) => ({ data: json })),

    savePushToken: (params) =>
        httpClient(`${apiUrl}/users/savePushToken`, {
            method: 'POST',
            body: JSON.stringify(params.data),
            headers: new Headers({
                'Content-Type': 'application/json',  
            }),
        }).then(({ json }) => ({ data: json })),
};


export default dataProvider;