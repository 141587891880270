import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslate, useNotify } from 'react-admin';

const ApiKeyDialog = ({ apiKey, open, onClose }) => {
    const translate = useTranslate();
    const notify = useNotify();

    const handleCopyApiKey = () => {
        if (apiKey) {
            navigator.clipboard.writeText(apiKey)
                .then(() => notify(translate('resources.installations.actions.api_key_copied'), { type: 'info' }))
                .catch(() => notify(translate('resources.installations.actions.api_key_copy_failed'), { type: 'warning' }));
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{translate("resources.installations.actions.api_key_generated_title")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{translate("resources.installations.actions.api_key_generated_text")}</DialogContentText>
                <Box display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={apiKey}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <IconButton onClick={handleCopyApiKey} edge="end">
                                    <ContentCopyIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate("resources.installations.actions.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApiKeyDialog;
