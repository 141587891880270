import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';

const ecoModernTheme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: '#009000', 
      light: '#66bc66', 
      dark: '#1B5E20',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#aaccaa', 
      light: '#EDE7F6',
      dark: '#8D6E63',
      contrastText: '#445144',
    },
    background: {
      default: '#fff', 
      paper: '#FFFFFF', 
    },
    text: {
      primary: '#2E3B2E', 
      secondary: '#4E5D4E', 
    },
    action: {
      hover: 'rgba(46, 125, 50, 0.08)', 
      selected: '#f2faf2', 
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
    error: {
      main: '#E57373',
      light: '#EF9A9A',
      dark: '#D32F2F',
    },
    warning: {
      main: '#FFB74D',
      light: '#FFE97D',
      dark: '#F57C00',
    },
    info: {
      main: '#BBDEFB', 
      light: '#E3F2FD',
      dark: '#90CAF9',
    },
    success: {
      main: '#66BB6A',
      light: '#A5D6A7',
      dark: '#388E3C',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#e9ecef',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    green:{
      50: '#f3f9f3',
      100:'#e2f1e2',
      900:'#415544',
    }
  },
  components: {
   
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          borderRadius: '0.5rem',
        },
      },
    },
   
  },
});

export default ecoModernTheme;