import React, { useState, useEffect } from 'react';
import { List, TextField, EditButton, useRecordContext, useTranslate, useDataProvider } from 'react-admin';
import { Box, Typography, useTheme , TableBody, TableCell, TableRow, TableContainer  } from '@mui/material';




const Legend = () => {
    const translate = useTranslate();
   const theme = useTheme();
    return (
        <Box ml={2} display="flex" flexWrap="wrap" alignItems="center">
            <Typography variant="caption" component="div" sx={{ marginRight: 2, color: theme.palette.success.main  }}>
                T1: {translate("resources.devices.fields.inactivity_duration")}
            </Typography>
            <Typography variant="caption" component="div" sx={{ marginRight: 2, color: theme.palette.success.main  }}>
                T2: {translate("resources.devices.fields.circuit_disconnect_delay")}
            </Typography>
            <Typography variant="caption" component="div" sx={{ marginRight: 2, color: theme.palette.success.main  }}>
                T3: {translate("resources.devices.fields.ac_disconnect_delay")}
            </Typography>
            <Typography variant="caption" component="div" sx={{ marginRight: 2, color: theme.palette.success.main  }}>
                T4: {translate("resources.devices.fields.ac_disconnect_window_open_delay")}
            </Typography>
        </Box>
    );
};

export default Legend;