import React, { useState } from 'react';
import { Create, useTranslate, useGetList, useRedirect } from 'react-admin';
import { CustomInstallationIcon, CustomDeviceIcon, CustomGroupIcon, CustomEventIcon } from '../pages/CustomIcons';
import ApiKeyDialog from './ApiKeyDialog';
import CustomSimpleForm from '../pages/CustomSimpleForm';
import { CustomDateInput, CustomNumberInput, CustomSelectInput, CustomTextInput } from '../pages/CustomInput';
import InputGrid from '../pages/InputGrid'; 

const InstallationCreate = (props) => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const { data: groups, isLoading: groupsLoading } = useGetList('group');
    const [apiKey, setApiKey] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleSuccess = (data) => {
        setApiKey(data.apiKey);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        redirect('/installations');
    };

    // Define los inputs en un array
    const inputs = [
        <CustomTextInput
            source="installation_name"
            fullWidth
            label={translate("resources.installations.fields.installation_name")}
            icon={CustomInstallationIcon}
        />,
        <CustomNumberInput
            source="max_devices"
            fullWidth
            label={translate("resources.installations.fields.max_devices")}
            icon={CustomDeviceIcon}
        />,
        !groupsLoading && (
            <CustomSelectInput
                source="group_id"
                choices={groups}
                optionText="group_name"
                optionValue="id"
                fullWidth
                label={translate("resources.installations.fields.group_name")}
                icon={CustomGroupIcon}
                sx={{marginTop:'0px'}}
            />
        ),
        <CustomDateInput
            source="license_expiration"
            fullWidth
            label={translate("resources.installations.fields.license_expiration")}
            icon={CustomEventIcon}
        />,
    ];

    return (
        <Create {...props} title={translate("resources.installations.create_page_title")} mutationOptions={{ onSuccess: handleSuccess }}>
            <CustomSimpleForm>
                <InputGrid inputs={inputs} /> 
            </CustomSimpleForm>
            <ApiKeyDialog apiKey={apiKey} open={dialogOpen} onClose={handleCloseDialog} />
        </Create>
    );
};

export default InstallationCreate;


