import React, { useState } from 'react';
import { Box, Chip, Button, Popover } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandableChipList = ({ items, maxDisplay = 2 }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', alignItems: 'center' }}>
            {items.slice(0, maxDisplay).map((item, index) => (
                <Chip key={index} label={item} size="small" />
            ))}
            {items.length > maxDisplay && (
                <>
                    <Button
                        size="small"
                        onClick={handleClick}
                        endIcon={<ExpandMoreIcon />}
                    >
                        +{items.length - maxDisplay}
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ p: 2, display: 'flex', flexWrap: 'wrap', gap: '4px', maxWidth: '300px' }}>
                            {items.slice(maxDisplay).map((item, index) => (
                                <Chip key={index} label={item} size="small" />
                            ))}
                        </Box>
                    </Popover>
                </>
            )}
        </Box>
    );
};

export default ExpandableChipList;
