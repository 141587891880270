import React, { useEffect, useState } from 'react';
import {TableContainer, Paper, Popover, CircularProgress, useTheme, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslate  } from 'react-admin';
import { SensorOccupied, SensorWindowOutlined, SensorDoorOutlined, CableOutlined, BluetoothOutlined } from '@mui/icons-material';
import { useDataProvider } from 'react-admin';



const SensorDetailsPopover = ({ deviceId, deviceName, anchorEl, mousePosition, onClose }) => {
    const dataProvider = useDataProvider();
    const [sensors, setSensors] = useState([]);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const t = useTranslate();

    useEffect(() => {
        const fetchSensors = async () => {
            if (deviceId && anchorEl) {
                setLoading(true);
                try {
                    const { data } = await dataProvider.getList('sensors', {
                        filter: { device_id: deviceId },
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'sensor_type', order: 'ASC' },
                    });
                    setSensors(data);
                } catch (error) {
                    console.error('Error fetching sensors:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchSensors();
    }, [deviceId, anchorEl, dataProvider]);

    const open = Boolean(anchorEl);
    const getSensorIcon = (sensorType) => {
        switch (sensorType) {
          case 'PIR': return <SensorOccupied color="grey" />;
          case 'pir' :return <SensorOccupied color="grey" />;
          case 'window': return <SensorWindowOutlined color="secondary" />;
          case 'windowSensor': return <SensorWindowOutlined color="secondary" />;
          case 'door': return <SensorDoorOutlined color="warning" />;
          case 'doorSensor' : return <SensorDoorOutlined color="warning" />;
          default: return <Typography> {sensorType} </Typography>;
        }
      };
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorReference={mousePosition ? "anchorPosition" : "anchorEl"}
            anchorPosition={mousePosition}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
         <Box p={0.3} sx={{bgcolor: theme.palette.secondary.main}}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        sensors.length > 0 ? (
                            <TableContainer component={Paper} >
                                <Table size="small" aria-label="sensor table">
                                <TableHead>
                                        <TableRow  >
                                            <TableCell colSpan={3} align="center">
                                                <Typography  >{deviceName}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {sensors.map((sensor) => (
                                        <TableRow key={sensor.sensor_id}>
                                        <TableCell>
                                            {sensor.subtype === "wired" 
                                            ? <CableOutlined fontSize="small" sx={{ color: '#757575', marginRight: '8px' }} />
                                            : <BluetoothOutlined fontSize="small" sx={{ color: '#2196f3', marginRight: '8px' }} />
                                            }
                                            {getSensorIcon(sensor.sensor_type)}
                                        </TableCell>
                                        
                                        <TableCell>{sensor.mac_address || " "}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <TableContainer component={Paper} >
                            <Table size="small" aria-label="sensor table">
                                <TableHead>
                                    <TableRow  >
                                        <TableCell colSpan={3} align="center">
                                            <Typography  >{deviceName}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                        {t('pages.SensorDetailsPopover.no_results')}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        )
                    )}
                </Box>
        </Popover>
    );
};

export default SensorDetailsPopover;
