import React, { useState, useEffect } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import { Box, Paper, Typography, MenuItem, Select, FormControl, InputLabel, useTheme, IconButton } from '@mui/material';
import {MoreVert, PersonOutline, Person} from '@mui/icons-material';
import SensorDetailsPopover from './SensorDetailsPopover';


const generateMockDevices = (numDevices) => {
    const devices = [];
    for (let i = 1; i <= numDevices; i++) {
        devices.push({
            id: i,
            device_name: `Device ${i}`,
            firmware_version: `1.0.${i}`,
            mac_address: `00:0a:95:9d:68:${i.toString().padStart(2, '0')}`,
            ip_address: `192.168.1.${i}`,
            occupancy_status: i % 2 === 0,
            last_event_time: new Date().toISOString(),
            last_config_time: new Date().toISOString(),
            inactivity_duration: `T1-${i}`,
            circuit_disconnect_delay: `T2-${i}`,
            ac_disconnect_delay: `T3-${i}`,
            ac_disconnect_window_open_delay: `T4-${i}`,
            ac_mode: `AC-${i}`,
            power_mode: `Power-${i}`,
        });
    }
    return devices;
};


const Dashboard = () => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [installations, setInstallations] = useState([]);
    const [installationId, setInstallationId] = useState('');
    const [devices, setDevices] = useState([]);
    const theme = useTheme();
    const [intervalTime] = useState(15000);  
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [selectedDeviceName, setSelectedDeviceName] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortBy, setSortBy] = useState('device_name');
    
    const now = new Date();

    useEffect(() => {
        const fetchInstallations = async () => {
            const { data } = await dataProvider.getList('installations', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'installation_name', order: 'ASC' },
                filter: {},
            });
            setInstallations(data);
            if (data.length > 0) {
                setInstallationId(data[0].id);
            }
        };

        fetchInstallations();
    }, [dataProvider]);

    useEffect(() => {
        const fetchDevices = async () => {
            if (installationId) {
                const { data } = await dataProvider.getList('devices', {
                    filter: { installation_id: installationId },
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'device_name', order: 'ASC' },
                });
              //const data = generateMockDevices(500);
                setDevices(data);
            }
        };

        fetchDevices();
        const interval = setInterval(fetchDevices, intervalTime); 
        return () => clearInterval(interval);
    }, [installationId, dataProvider]);

    const handleInstallationChange = (event) => {
        setInstallationId(event.target.value);
    };

    const handleSensorClick = (event, deviceId, deviceName) => {
        setAnchorEl(event.currentTarget);
        setSelectedDeviceId(deviceId);
        setSelectedDeviceName(deviceName);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setSelectedDeviceId(null);
    };

    const isStale = (lastEventTime) => {
        const eventDate = new Date(lastEventTime);
        const timeDiff = now - eventDate;
        return timeDiff > 24 * 60 * 60 * 1000; // 24 hours
    };

    const sortedDevices = [...devices].sort((a, b) => {
        if (sortBy === 'device_name') {
            return a.device_name.localeCompare(b.device_name, undefined, { numeric: true, sensitivity: 'base' });
   
        } else if (sortBy === 'occupancy_status') {
          return b.occupancy_status - a.occupancy_status;
        } else if (sortBy === 'stale') {
          return isStale(a.last_event_time) - isStale(b.last_event_time);
        }
        return 0;
      });

    return (
        <Box>
            <Box mb={3} display="flex" alignItems="center" gap={2}>
            <FormControl variant="outlined" sx={{ minWidth: 300 }}>
                <InputLabel>{translate("resources.installations.name")}</InputLabel>
                <Select
                    value={installationId}
                    onChange={handleInstallationChange}
                    label={translate("resources.installations.name")}
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                        },
                    }}
                    >
                    {installations.map(installation => (
                        <MenuItem key={installation.id} value={installation.id}>
                        {installation.installation_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                <InputLabel>{translate('pages.dashboard.select_order_by.label')}</InputLabel>
                <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    label={translate('pages.dashboard.select_order_by.label')}
                >
                <MenuItem value="device_name">{translate('pages.dashboard.select_order_by.option_1')}</MenuItem>
                <MenuItem value="occupancy_status">{translate('pages.dashboard.select_order_by.option_2')}</MenuItem>
                <MenuItem value="stale">{translate('pages.dashboard.select_order_by.option_3')}</MenuItem>
                </Select>
            </FormControl>
            </Box>

            <Box 
                display="grid" 
                gridTemplateColumns="repeat(auto-fill, minmax(160px, 1fr))" 
                gap={2}
                sx={{ p: 2 }}
            >
                {sortedDevices.map(device => (
                <Paper
                    key={device.id}
                    elevation={0}
                    sx={{
                        height: 80,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '0.75em',
                        borderRadius: 2,
                        backgroundColor: device.occupancy_status ? '#f3f9f3' : 'white',
                        border: isStale(device.last_event_time)
                        ? '2px solid #f44336'  
                        : `1px solid ${device.occupancy_status ? 'rgba(76, 175, 80, 0.2)' : '#e0e0e0'}`,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            transform: 'translateY(-2px)',
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {device.occupancy_status ? (
                                <Person sx={{ color: '#757575', fontSize: '1.4rem', mr: 1 }} />
                            ) : (
                                <PersonOutline sx={{ color: '#cdcdcd', fontSize: '1.4rem', mr: 1 }} />
                            )}
                            <Typography noWrap sx={{ fontSize: '1rem', fontWeight: 500, color: '#333' }}>
                                {device.device_name}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                size="small"
                                onClick={(event) => handleSensorClick(event, device.id, device.device_name)}
                                sx={{ 
                                    p: 0.5,
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    }
                                }}
                            >
                                <MoreVert sx={{ fontSize: '1.3rem', color:'#757575' }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Paper>
             ))}
            </Box>
            <SensorDetailsPopover
                deviceId={selectedDeviceId}
                deviceName={selectedDeviceName}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
            />
            </Box>
    );
};

export default Dashboard;
/*
<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
<Typography 
    sx={{ 
      fontSize: '0.75rem', 
      backgroundColor: 'blue', 
      color: 'white', 
      px: 0.5, 
      borderRadius: 0.5 
    }}
  >
    La suite
  </Typography>
 
  <Typography 
    sx={{ 
      fontSize: '0.75rem', 
      backgroundColor: '#00ff00', 
      color: 'white', 
      px: 0.5, 
      borderRadius: 0.5 
    }}
  >
    Label 2
  </Typography>  
</Box>*/