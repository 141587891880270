const spanishCustomMessages = {
  messages: {
    room_occupied: "Habitación ocupada",
    room_unoccupied: "Habitación desocupada",
  },
  pages: {
   dashboard:{
      menu:"Estado actual",
      select_order_by:{
        label: "Ordenar por",
        option_1: "Nombre",
        option_2: "Ocupación",
        option_3: "Estado de conexión",
      } ,
    },
    SensorDetailsPopover:{
      no_results: "No se han encontrado sensores",
    }
  },
  resources: {
    group: {
      name: "Grupos",
      edit_title: "Editar grupo",
      associated_installations: "Instalaciones",
      fields: {
        id: "ID",
        group_name: "Nombre del Grupo",
        owner_info: "Información del Propietario",
        description: "Descripción",
      },
      actions: {
        delete_conflict: "Elimine primero las instalaciones asociadas a este grupo",
        installations_loading: "Loading ... ",
        installations_empty: "No hay instalaciones asociadas",
      },
    },
    installations: {
      edit_page_title: "Actualizar instalación",
      create_page_title: "Crear nueva installation",
      name: "Instalaciones",
      all: "Todas",
      fields: {
        id: "ID",
        group_name: "Nombre del Grupo",
        installation_name: "Nombre de la Instalación",
        api_key: "Clave API",
        max_devices: "Número Máximo de Dispositivos",
        license_expiration: "Fecha de Expiración de la Licencia",
      },
      actions: {
        change_api_key: "Cambiar API key",
        confirm_change_api_key_title: "Confirmar cambio de API key",
        confirm_change_api_key_text: "¿Está seguro de que desea cambiar la API key para esta instalación?",
        api_key_generated_title: "API key creada",
        api_key_generated_text: "Se ha generado una nueva API key para esta instalación. Cópiela ahora, ya que no se mostrará nuevamente.",
        api_key_copied: "API key copiada al portapapeles",
        api_key_copy_failed: "Error al copiar la API key",
        cancel: "Cancelar",
        confirm: "Confirmar",
        copy: "Copiar",
        close: "Cerrar"
      },
    },
    devices: {
      name: "Dispositivos",
      all:"Todos",
      fields: {
        id: "ID",
        installation_id: "ID inst.",
        installation_name: "Inst.",
        device_name: "Nombre",
        device_type: "Tipo",
        firmware_version: "Vers.",
        mac_address: "MAC",
        ip_address: "IP",
        occupancy_status: "Oc.",
        last_event_time: "Último Evento",
        last_config_time: "Última Conf.",
        inactivity_duration: "Tiempo sin movimiento para determinar ausencia (seg)",
        circuit_disconnect_delay: "Tiempo antes de cortar energía tras detectar ausencia (seg)",
        ac_disconnect_delay: " Tiempo antes de cortar el aire tras detectar ausencia (seg)",
        ac_disconnect_window_open_delay: "Tiempo desde que se abre una ventana hasta cortar el aire (seg)",
        ac_mode: "AC",
        power_mode: "Energía",
      },
    },
    events: {
      name: "Eventos",
      fields: {
        id: "ID",
        device_id: "Id del controlador",
        device_name: "Controlador",
        event_key: "Clave",
        event_value: "Valor",
        timestamp: "Fecha hora",
        timestamp_from: "Desde",
        timestamp_to: "Hasta",
      },
    },
    users: {
      name: "Usuarios",
      edit_page_title: "Editar usuario",
      change_password_message: "La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y símbolos",
      fields: {
        id: "ID",
        user_name: "Nombre de usuario",
        role_name: "Rol",
        active: "Activo",
        email: "Email",
        installations: "Instalaciones",
        groups: "Grupos",
        new_password: "Nueva contraseña",
        password: "Contraseña"
      },
      roles:{
        Maint2: "Monitor",
        Maint1: "Mantenedor",
        InstallationManager: "Director de instalación",
        GroupManager:"Director de grupo",
        Superuser: "Administrador del sistema"
      },
      actions:{
        change_password: "Cambiar contraseña",
        add: "Añadir",
        password_changed_success:"Contraseña cambiada satisfactoriamente"
      },
      sections:{
        user_info: "Datos de usuario",
        group_management: "Asignación de grupos",
        installation_management: "Asignación de instalaciones"
      }
    },
  },
};

export default spanishCustomMessages;

