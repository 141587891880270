const englishCustomMessages = {
  messages: {
    room_occupied: "Room {{name}} occupied",
    room_unoccupied: "Room {{name}} unoccupied",
  },
  pages: {
    dashboard:{
      menu:"Status",
      select_order_by:{
        label: "Order by",
        option_1: "Device name",
        option_2: "Occupancy status",
        option_3: "Connection status",
      } ,
    },
    SensorDetailsPopover:{
      no_results: "No sensors found",
    }
  },
  resources: {
    group: {
      name: "Groups",
      edit_title: "Update group",
      associated_installations: "Installations",
      fields: {
        id: "ID",
        group_name: "Group Name",
        owner_info: "Owner Info",
        description: "Description",
      },
      actions: {
        delete_conflict: "Please, delete first the associated installations",
        installations_loading: "Loading ... ",
        installations_empty: "No associated installations found",
      },
    },
    installations: {
      edit_page_title: "Update installation",
      create_page_title: "Create a new installation",
      name: "Installations",
      all: "All",
      fields: {
        id: "ID",
        group_name: "Group name",
        installation_name: "Installation name",
        api_key: "API Key",
        max_devices: "Max nr. of devices",
        license_expiration: "License expiration",
      },
      actions: {
        change_api_key: "Change API Key",
        confirm_change_api_key_title: "Confirm Change API Key",
        confirm_change_api_key_text: "Are you sure you want to change the API Key for this installation?",
        api_key_generated_title: "API Key Generated",
        api_key_generated_text: "The API key has been successfully generated. Please copy it now as it will not be shown again.",
        api_key_copied: "API key copied to clipboard",
        api_key_copy_failed: "Failed to copy API key",
        cancel: "Cancel",
        confirm: "Confirm",
        copy: "Copy",
        close: "Close",
      },
    },
    devices: {
      name: "Devices",
      fields: {
        id: "ID",
        installation_id: "Installation ID",
        installation_name: "Inst.",
        device_name: "Name",
        device_type: "Type",
        firmware_version: "Vers.",
        mac_address: "MAC",
        ip_address: "IP",
        occupancy_status: "Occ.",
        last_event_time: "Last Event",
        last_config_time: "Last Config",
        inactivity_duration: "Time without movement to determine absence (sec)",
        circuit_disconnect_delay: "Time before cutting power after detecting absence (sec)",
        ac_disconnect_delay: "Time before turning off the air conditioning after detecting absence (sec)",
        ac_disconnect_window_open_delay: "Time from opening a window until air disconnect (sec)",
        ac_mode: "AC",
        power_mode: "Main",
      },
    },
    events: {
      name: "Events",
      fields: {
        id: "ID",
        device_id: "Controller ID",
        device_name: "Controller",
        event_key: "Key",
        event_value: "Value",
        timestamp: "Date time",
        timestamp_from: "From",
        timestamp_to: "To"
      },
    },
    users: {
      name: "Users",
      edit_page_title: "Update user",
      change_password_message: "The password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and symbols",
      fields: {
        id: "ID",
        user_name: "Username",
        role_name: "Role",
        active: "Active",
        email: "Email",
        installations: "Installations",
        groups: "Groups",
        new_password: "New password",
        password: "Password"
      },
      roles:{
        Maint2: "Viewer",
        Maint1: "Maintainer",
        InstallationManager: "Installation manager",
        GroupManager:"Group manager",
        Superuser: "System administrator"
      },
      actions:{
        change_password: "Change password",
        add: "Add",
        password_changed_success: "Password changed successfully"
      },
      sections:{
        user_info: "User info",
        group_management: "Group assignment",
        installation_management: "Installation assignment"
      }
    },
  },
};

export default englishCustomMessages;
