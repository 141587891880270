import React from 'react';
import { List, TextField, EditButton, useTranslate, useNotify, useRefresh } from 'react-admin';
import CustomDataGrid from '../CustomDatagrid';
import CustomDeleteButton from '../pages/CustomDeleteButton';

const GroupList = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleDeleteSuccess = () => {
        notify(translate("ra.notification.deleted"), { type: 'info' });
        refresh();
    };

    const handleDeleteError = (error) => {
        if (error.status === 409) {
            notify(translate("resources.group.actions.delete_conflict"), { type: 'warning' });
        } else {
            notify(error.message || translate("ra.notification.http_error"), { type: 'warning' });
        }
    };

    return (
        <List {...props} perPage={25} >
            <CustomDataGrid rowClick="edit">
                <TextField source="group_name" label={translate("resources.group.fields.group_name")} />
                <TextField source="description" label={translate("resources.group.fields.description")} />
                <EditButton label=""/>
                <CustomDeleteButton
                    resource="group"
                    onSuccess={handleDeleteSuccess}
                    onError={handleDeleteError}
                />
            </CustomDataGrid>
        </List>
    );
};

export default GroupList;


