import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB6A8h_hD8h2Z8UirrIyZlcnlJ9SDcaGYg",
  //authDomain: "c591-81-42-250-52.ngrok-free.app",
  authDomain: "eco-test.roommatik.com",
  projectId: "roommatik-eco",
  storageBucket: "roommatik-eco.appspot.com",
  messagingSenderId: "790049531622",
  appId: "1:790049531622:web:f17d77f04f1cdb7af47d27",
  measurementId: "G-VMK6Q4CV3B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging and handle errors
let messaging;
try {
  messaging = getMessaging(app);
} catch (error) {
  console.error("Error initializing Firebase Messaging:", error);
}

// Request permission to show notifications and get the token
export const requestPermission = async (saveToken) => {
  if (!messaging) {
    console.error("Firebase Messaging is not initialized.");
    return;
  }

  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BGhFYhKZ8GnQ0CwLII-rsirid4LmTn73Tsjvl1dOlFUemxqmwpSRP26BIHieaL1kkfhzx_40nRPLPc8o2694qsE' });
    if (currentToken) {
      console.log('Current token for client: ', currentToken);
      if (saveToken) {
        try {
          await saveToken(currentToken);
          console.log('Push token saved successfully');
        } catch (error) {
          console.error('Failed to save push token:', error);
        }
      }
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

// Listener for foreground messages
export const onMessageListener = (callback) => {
  if (!messaging) {
    console.error("Firebase Messaging is not initialized.");
    return;
  }

  onMessage(messaging, (payload) => {
    console.log('Message received in foreground: ', payload);
    callback(payload); 
  });
};





/*import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyB6A8h_hD8h2Z8UirrIyZlcnlJ9SDcaGYg",
    authDomain: "c591-81-42-250-52.ngrok-free.app",
    projectId: "roommatik-eco",
    storageBucket: "roommatik-eco.appspot.com",
    messagingSenderId: "790049531622",
    appId: "1:790049531622:web:f17d77f04f1cdb7af47d27",
    measurementId: "G-VMK6Q4CV3B"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async (saveToken) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: 'BGhFYhKZ8GnQ0CwLII-rsirid4LmTn73Tsjvl1dOlFUemxqmwpSRP26BIHieaL1kkfhzx_40nRPLPc8o2694qsE' });
    if (currentToken) {
      console.log('current token for client: ', currentToken);
     
      if (saveToken) {
        try {
          await saveToken(currentToken);
          console.log('Push token saved successfully');
        } catch (error) {
          console.error('Failed to save push token:', error);
        }
      }
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      resolve(payload);
    });
  });*/
