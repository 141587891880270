import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandableChipList from '../pages/ExpandableChipList'; // Reutilizamos el componente de chip para mostrar grupos e instalaciones.
import { useTranslate } from 'react-admin';

const CustomUserList = ({ users, onEdit, onDelete }) => {
    const translate = useTranslate();

    return (
        <Box sx={{ maxHeight: '500px', overflow: 'auto', width: '100%' }}>
            <List>
                {users.map(user => (
                    <ListItem key={user.id} divider>
                        <ListItemText
                            primary={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                  
                                    <Typography sx={{ minWidth: '150px' }}>
                                        {user.username}
                                    </Typography>
                                    <Typography variant="body2" sx={{ minWidth: '200px' }}>
                                        {translate(`resources.users.roles.${user.role_name.replace(/[\s.]+/g, '')}`)}
                                    </Typography>

                                    {user.role_level === 1 && (
                                        <Box sx={{ minWidth: '300px' }}>
                                            <ExpandableChipList items={user.groups || []} maxDisplay={5} />
                                        </Box>
                                    )}

                                    {user.role_level > 1 && (
                                        <Box sx={{ minWidth: '300px' }}>
                                            <ExpandableChipList items={user.installations || []} maxDisplay={5} />
                                        </Box>
                                    )}
                                </Box>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => onEdit(user.id)}>
                                <EditIcon />
                            </IconButton>
                            {user.username !== 'admin' && (
                                <IconButton edge="end" onClick={() => onDelete(user.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default CustomUserList;



