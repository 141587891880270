import React from 'react';
import { List, useTranslate, useGetList, Title, useNotify, useRedirect } from 'react-admin';
import CustomUserList from './CustomUserList';

const UserList = (props) => {
    const translate = useTranslate();
    const { data: users, isLoading } = useGetList('users');
    const notify = useNotify();
    const redirect = useRedirect();

    const handleEdit = (id) => {
        redirect(`/users/${id}`);
    };

    const handleDelete = async (id) => {
        try {
            // Implementa la lógica de eliminación aquí.
            notify('User deleted successfully', { type: 'success' });
        } catch (error) {
            notify('Error deleting user', { type: 'warning' });
        }
    };

    if (isLoading) return null;

    return (
        <List {...props} title={<Title title={translate('resources.users.name')} />}>
            <CustomUserList users={users} onEdit={handleEdit} onDelete={handleDelete} />
        </List>
    );
};

export default UserList;










/*import React from 'react';
import { List, EditButton, DeleteButton, useTranslate, useGetList, Title } from 'react-admin';
import { Box, Typography, Chip, Card, CardContent, CardActions, IconButton, Avatar, Grid, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const formatLabel = (label) => {
    if (label === '-') return null;
    const parts = label.split('-');
    return parts.length > 1 ? parts[1].trim() : label;
};

const UserCard = ({ user, loggedInUsername }) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => setExpanded(!expanded);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <Card sx={{
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            transition: 'all 0.3s',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                transform: 'translateY(-2px)'
            },
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.secondary.main}`,
        }}>
            <CardContent sx={{ flexGrow: 1, padding: isXs ? 1 : 2 }}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between', 
                    marginBottom: '8px'
                }}>
                    <Typography variant="subtitle1" component="div">
                        {user.username}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 'auto', 
                    }}>
                        <EditButton
                            record={user}
                            label=''
                            sx={{
                                color: theme.palette.primary.main,
                                padding: '4px 0px 4px 7px ',
                                marginRight:'2px',
                                minWidth: 'auto',
                            
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </EditButton>
                        {loggedInUsername !== user.username && (
                            <DeleteButton
                                record={user}
                                label=''
                                sx={{
                                    color: theme.palette.error.main,
                                    padding: '4px 0px 4px 7px ',
                                    minWidth: 'auto',
                                
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </DeleteButton>
                        )}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px' }}>
                    
                </Box>

                {user.role_level === 1 && user.groups.some(group => group !== '-') && (
                    <Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px', marginTop: '15px' }}>
                            {user.groups.map((group, index) => {
                                const formattedGroup = formatLabel(group);
                                return formattedGroup && (
                                    <Chip
                                        key={index}
                                        label={formattedGroup}
                                        size="small"
                                        sx={{
                                        
                                            backgroundColor: theme.palette.primary.light,
                                            color: theme.palette.primary.contrastText,
                                            height: 20,
                                            fontSize: '0.75rem',
                                            
                                        }}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                )}

                {user.role_level > 1 && user.installations.some(installation => installation !== '-') && (
                    <Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
                            {(expanded ? user.installations : user.installations.slice(0, 3)).map((installation, index) => {
                                const formattedInstallation = formatLabel(installation);
                                return formattedInstallation && (
                                    <Chip
                                        key={index}
                                        label={formattedInstallation}
                                        size="small"
                                        sx={{
                                            
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            height: 20,
                                            fontSize: '0.75rem',
                                        
                                        }}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end', padding: isXs ? '4px 8px' : '8px 16px' }}>
                {user.role_level > 1 && user.installations.length > 3 && (
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        size="small"
                        sx={{
                            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s'
                        }}
                    >
                        <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                )}
            </CardActions>
        </Card>   
    );
};

const UsersByRole = ({ roleName, roleUsers, translate, loggedInUsername }) => {
    const theme = useTheme();

    return (
        <>
            <Box sx={{
                marginBottom: '12px',
                display:'flex',
                padding: '8px',
                borderRadius: '10px',
                maxHeight: '1000px',
                overflow: 'auto',
                bgcolor:theme.palette.grey[100],
            }}>
                <Box sx={{width:'150px', height:'auto' }}>

                    <Typography sx={{fontSize:'1.1em', color:theme.palette.primary.main }}>
                    {translate(`resources.users.roles.${roleName.replace(/[\s.]+/g, '')}`)}
                    </Typography>
                </Box>
                <Grid container spacing={1}>
                    {roleUsers.map(user => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={user.id}>
                            <UserCard
                                user={user}
                                translate={translate}
                                loggedInUsername={loggedInUsername}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

const UserList = (props) => {
    const translate = useTranslate();
    const loggedInUsername = localStorage.getItem("username");
    const { data: users, isLoading } = useGetList('users');
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    if (isLoading) return <Typography>{translate('ra.page.loading')}</Typography>;

    const usersByRole = users.reduce((acc, user) => {
        if (!acc[user.role_name]) {
            acc[user.role_name] = [];
        }
        acc[user.role_name].push(user);
        return acc;
    }, {});

    return (
        <List {...props} title={<Title title={translate('resources.users.name')} />}>
            <Box sx={{ padding: isXs ? '6px' : '8px' }}>
                <Grid container spacing={2} justifyContent="center">
                    {Object.entries(usersByRole).map(([roleName, roleUsers]) => (
                        <Grid item xs={12} key={roleName}>
                            <UsersByRole
                                roleName={roleName}
                                roleUsers={roleUsers}
                                translate={translate}
                                loggedInUsername={loggedInUsername}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </List>
    );
};

export default UserList;*/