import React, { useState, useEffect } from 'react';
import { List, TextField, DateField, useTranslate, useDataProvider } from 'react-admin';
import CustomDataGrid from '../CustomDatagrid';
import ExtendedFilter from './ExtendedFilter';

const EventList = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [defaultFilterValues, setDefaultFilterValues] = useState({});

    useEffect(() => {
        const fetchInstallations = async () => {
            const { data } = await dataProvider.getList('installations', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'installation_name', order: 'ASC' },
                filter: {},
            });
    
            if (data && data.length > 0) {
                setDefaultFilterValues({ installation_id: data[0].id });
            }
        };
    
        fetchInstallations();
    }, [dataProvider]);
    
    return (
        <List 
            {...props}  
            filters={<ExtendedFilter />} 
            sort={{ field: 'installation_name', order: 'ASC' }}
            perPage={50}
            filterDefaultValues={defaultFilterValues} 
        >
            <CustomDataGrid >
                <TextField source="installation_name" label={translate("resources.devices.fields.installation_name")} />
                <TextField source='device_name' label={translate("resources.events.fields.device_name")} />
                <DateField source="timestamp" label={translate("resources.events.fields.timestamp")} showTime />
                <TextField source="event_key" label={translate("resources.events.fields.event_key")} />
                <TextField source="event_value" label={translate("resources.events.fields.event_value")} />
            </CustomDataGrid>
        </List>
    );
};

export default EventList;
