import React, { useEffect, useState } from 'react';
import { Toolbar, SaveButton, Edit, useGetOne, useTranslate, useDataProvider, useRedirect, useNotify } from 'react-admin';
import { Typography, Chip, Grid, Paper, Divider, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import ChangePasswordDialog from './ChangePasswordDialog';
import CustomSimpleForm from '../pages/CustomSimpleForm';
import InputGrid from '../pages/InputGrid';
import { CustomTextInput, CustomSelectInput, CustomButton } from '../pages/CustomInput';
import AddIcon from '@mui/icons-material/Add';
import { CustomEmailIcon, CustomGroupIcon, CustomInstallationIcon, CustomPasswordIcon, CustomRoleIcon, CustomUserIcon } from '../pages/CustomIcons';

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton alwaysEnable />
    </Toolbar>
);

const UserEdit = (props) => {
    const { id } = useParams();
    const { data: record, isLoading } = useGetOne('users', { id });
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const redirect = useRedirect();
    const notify = useNotify();

    const [groups, setGroups] = useState([]);
    const [installations, setInstallations] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedInstallation, setSelectedInstallation] = useState('');
    const [userGroups, setUserGroups] = useState([]);
    const [userInstallations, setUserInstallations] = useState([]);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [passwordChanged, setPasswordChanged] = useState(false);

    useEffect(() => {
        if (record && record.role_level >= 1) {
            dataProvider.getList('group', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'group_name', order: 'ASC' },
                filter: {}
            }).then(({ data }) => {
                setGroups(data.map(group => ({ id: group.id, name: group.group_name })));
            });
        }
    }, [record, dataProvider]);

    useEffect(() => {
        if (selectedGroup) {
            dataProvider.getList('installations', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'installation_name', order: 'ASC' },
                filter: { group_id: selectedGroup }
            }).then(({ data }) => {
                setInstallations(data.map(installation => ({ id: installation.id, name: installation.installation_name })));
            });
        } else {
            setInstallations([]);
        }
    }, [selectedGroup, dataProvider]);

    useEffect(() => {
        if (record) {
            setUserGroups(record.groups);
            setUserInstallations(record.installations);
        }
    }, [record]);

    const handleSubmit = async (data) => {
        const updatedData = {
            ...data,
            groups: userGroups,
            installations: userInstallations,
        };

        try {
            await dataProvider.update('users', {
                id: data.id,
                data: updatedData,
                previousData: record
            });
            redirect('/users');
        } catch (error) {
            if (error.status === 409) {
                notify('A user with this username or email already exists.', { type: 'warning' });
            } else {
                console.error('Error updating user:', error);
                notify('An error occurred while updating the user.', { type: 'error' });
            }
        }
    };

    const handleAddGroup = () => {
        const groupName = groups.find(group => group.id === selectedGroup)?.name;
        if (selectedGroup && groupName && !userGroups.includes(groupName)) {
            setUserGroups([...userGroups, groupName]);
            setSelectedGroup('');
        }
    };

    const handleAddInstallation = () => {
        const selectedInstallationObj = installations.find(installation => installation.id === selectedInstallation);
        if (selectedInstallationObj && !userInstallations.some(installation => installation === selectedInstallationObj.name)) {
            setUserInstallations([...userInstallations, selectedInstallationObj.name]);
            setSelectedInstallation('');
        }
    };

    const handleDeleteGroup = (groupToDelete) => () => {
        setUserGroups(userGroups.filter(group => group !== groupToDelete));
    };

    const handleDeleteInstallation = (installationToDelete) => () => {
        setUserInstallations((installations) => installations.filter((installation) => installation !== installationToDelete));
    };

    const handlePasswordDialogOpen = () => {
        setPasswordDialogOpen(true);
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        setPasswordChanged(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!record) {
        return <div>No data</div>;
    }

    const inputs = [
        <CustomTextInput
            source="role_name"
            label={translate("resources.users.fields.role_name")}
            defaultValue={translate(`resources.users.roles.${record.role_name.replace(/[\s.]+/g, '')}`)}
            readOnly={true}
            icon ={CustomRoleIcon}
            fullWidth
        />,
        <CustomTextInput
            source="username"
            label={translate("resources.users.fields.user_name")}
            icon ={CustomUserIcon}
            fullWidth
        />,
        <CustomTextInput
            source="email"
            label={translate("resources.users.fields.email")}
            icon ={CustomEmailIcon}
            fullWidth
        />
    ];

    const buttons = [
        <CustomButton
            variant="contained"
            color="primary"
            onClick={handlePasswordDialogOpen}
            startIcon= {<CustomPasswordIcon />}
            label = {translate("resources.users.actions.change_password")}
        >  
        </CustomButton>
    ];

    return (
        <Edit {...props} record={record} title={translate("resources.users.edit_page_title")}>
            <CustomSimpleForm onSubmit={handleSubmit} toolbar={<CustomToolbar />}>
                <InputGrid inputs={inputs} buttons={buttons} />
                <ChangePasswordDialog
                    open={passwordDialogOpen}
                    onClose={handlePasswordDialogClose}
                    userId={id}
                />
                {passwordChanged && (
                    <Typography variant="body2" color="success" sx={{ marginTop: 2 }}>
                        {translate("resources.users.actions.password_changed_success")}
                    </Typography>
                )}
    
                <Divider sx={{ marginBottom: '20px', marginTop: '20px' }} />
    
                {/* Contenedor para colocar SelectInput de grupos e instalaciones uno al lado del otro */}
                <Box display="flex" gap={2}>
                    <CustomSelectInput
                        source="groups"
                        choices={groups}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        icon={CustomGroupIcon}
                        fullWidth
                    />
    
                    {record.role_level > 1 && (
                        <CustomSelectInput
                            source="installations"
                            choices={installations}
                            onChange={(e) => setSelectedInstallation(e.target.value)}
                            icon={CustomInstallationIcon}
                            fullWidth
                        />
                    )}
                </Box>
    
                {record.role_level > 1 && (
                    <CustomButton
                        onClick={handleAddInstallation}
                        startIcon={<AddIcon />}
                        variant="contained"
                        disabled={!selectedInstallation}
                        label={translate("resources.users.actions.add")}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                    />
                )}
    
                {record.role_level === 1 && (
                    <CustomButton
                        onClick={handleAddGroup}
                        startIcon={<AddIcon />}
                        variant="contained"
                        disabled={!selectedGroup}
                        label={translate("resources.users.actions.add")}
                        sx={{ marginTop: 2, marginBottom: 2 }}
                    />
                )}
    
                <Box>
                    {record.role_level === 1 &&
                        userGroups.map((group, index) => (
                            <Chip
                                key={index}
                                label={group}
                                onDelete={handleDeleteGroup(group)}
                                color="primary"
                                variant="outlined"
                                sx={{ m: 0.5 }}
                            />
                        ))}
    
                    {record.role_level > 1 &&
                        userInstallations.map((installation, index) => (
                            <Chip
                                key={index}
                                label={`${installation}`}
                                onDelete={handleDeleteInstallation(installation)}
                                color="primary"
                                variant="outlined"
                                sx={{ m: 0.5 }}
                            />
                        ))}
                </Box>
            </CustomSimpleForm>
        </Edit>
    );
    
    
};

export default UserEdit;


