import React, { useState, useEffect } from 'react';
import { List, TextField, useRecordContext, useTranslate, useDataProvider, useRefresh, useTheme  } from 'react-admin';
import { Box, IconButton } from '@mui/material';
import { Person, PersonOutline, MoreVert } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/es';
import CustomDataGrid from '../CustomDatagrid';
import DeviceFilter from '../DeviceComponents/DeviceFilter';
import Legend from './Legend';
import SensorDetailsPopover from '../pages/SensorDetailsPopover';

const DeviceList = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [defaultFilterValues, setDefaultFilterValues] = useState({});
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [selectedDeviceName, setSelectedDeviceName] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mousePosition, setMousePosition] = useState(null);
    const intervalTime = 15000;

    useEffect(() => {
        const fetchInstallations = async () => {
                const { data } = await dataProvider.getList('installations', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'installation_name', order: 'ASC' },
                filter: {},
            });

            if (data && data.length > 0) {
                setDefaultFilterValues({ installation_id: data[0].id });
            }
        };

        fetchInstallations();
    }, [dataProvider]);

    useEffect(() => {
        const interval = setInterval(() => {
            refresh(); 
        }, intervalTime);

        return () => clearInterval(interval); 
    }, [refresh, intervalTime]);
  
    const CustomOccupancyField = ({ source }) => {
        const record = useRecordContext();
        return record[source] ? <Person style={{ color: '#757575' }} /> : <PersonOutline style={{ color: '#ccc' }} /> ;
    };

    const ElapsedTimeField = ({ source }) => {
        const record = useRecordContext();
        if (!record || !record[source]) return null;

        const formatElapsedTime = (date) => {
            const locale = translate('locale') === 'es' ? 'es' : 'en-gb';
            moment.locale(locale);
            return moment(date).fromNow();
        };

        return <span style={{ fontSize: '0.9rem' }}>{formatElapsedTime(record[source])}</span>;
    };

    const SensorInfoButton = () => {
        const record = useRecordContext();

        return (
            <IconButton
                onClick={(event) => handleSensorClick(event, record.id, record.device_name)}
                sx={{
                    padding: '2px',
                    margin: 0,
                    minWidth: 'auto',
                    minHeight: 'auto',
                    
                }}
            >
                <MoreVert 
                   
                    sx={{ 
                        color: '#555', 
                        fontSize: '20px'
                    }} 
                />
            </IconButton>
        );
    };
    
    const handleSensorClick = (event, deviceId, deviceName) => {
        setAnchorEl(event.currentTarget);
        setSelectedDeviceId(deviceId);
        setSelectedDeviceName(deviceName);
        setMousePosition({ left: event.clientX, top: event.clientY });
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        setSelectedDeviceId(null);
        setMousePosition(null);
    };

    return (
        <List
            {...props}
            filters={<DeviceFilter />}
            sort={{ field: 'installation_name', order: 'ASC' }}
            perPage={50}
            filterDefaultValues={defaultFilterValues}
            sx={{
                '& .RaList-main': {
                    overflowX: 'auto',
                },
            }}
        >
            <Box mb={0}>
                <Legend />
            </Box>
            <CustomDataGrid>
           
                <CustomOccupancyField source="occupancy_status" label={translate("resources.devices.fields.occupancy_status")} />
                <TextField source="installation_name" label={translate("resources.devices.fields.installation_name")} />
                <TextField source="device_name" label={translate("resources.devices.fields.device_name")} />
                <TextField source="firmware_version" label={translate("resources.devices.fields.firmware_version")} />
                <TextField source="mac_address" label={translate("resources.devices.fields.mac_address")} />
                <TextField source="ip_address" label={translate("resources.devices.fields.ip_address")} />
                <ElapsedTimeField source="last_event_time" label={translate("resources.devices.fields.last_event_time")} />
                <ElapsedTimeField source="last_config_time" label={translate("resources.devices.fields.last_config_time")} />
                <TextField source="inactivity_duration" label="T1" />
                <TextField source="circuit_disconnect_delay" label="T2" />
                <TextField source="ac_disconnect_delay" label="T3" />
                <TextField source="ac_disconnect_window_open_delay" label="T4" />
                <TextField source="ac_mode" label={translate("resources.devices.fields.ac_mode")} />
                <TextField source="power_mode" label={translate("resources.devices.fields.power_mode")} />
                <SensorInfoButton />
            </CustomDataGrid>
            <SensorDetailsPopover
                deviceId={selectedDeviceId}
                deviceName={selectedDeviceName}
                anchorEl={anchorEl}
                mousePosition={mousePosition}
                onClose={handleClosePopover}
            />
        </List>
    );
};

export default DeviceList;


