import React, { useState, useEffect } from "react";
import { Create, SimpleForm, TextInput, SelectInput, useDataProvider, useTranslate, useRedirect } from 'react-admin';
import { Box, Button, Chip, Grid, Paper, Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { validatePassword } from "../../utils/validatePassword";

const UserCreate = props => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const redirect = useRedirect();
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [groups, setGroups] = useState([]);
    const [installations, setInstallations] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedInstallation, setSelectedInstallation] = useState('');
    const [userGroups, setUserGroups] = useState([]);
    const [userInstallations, setUserInstallations] = useState([]);

    useEffect(() => {
        dataProvider.getList('roles', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'role_name', order: 'ASC' },
            filter: {}
        }).then(({ data }) => {
            setRoles(data.map(role => ({ 
                id: role.id, 
                name: role.role_name, 
                level: role.level 
            })));  
        });
    }, [dataProvider]);

   

    useEffect(() => {
        if (selectedRole && selectedRole.level === 1) { 
            // Cargar grupos si el rol seleccionado es 'Group Manager'
            dataProvider.getList('groups', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'group_name', order: 'ASC' },
                filter: {}
            }).then(({ data }) => {
                setGroups(data.map(group => ({ id: group.id, name: group.group_name })));
                setInstallations([]); // Limpiar instalaciones cuando el rol es de nivel 1
            });
        } else if (selectedRole && selectedRole.level > 1) { 
            // Cargar grupos si el rol es 'Installation Manager' o superior
            dataProvider.getList('groups', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'group_name', order: 'ASC' },
                filter: {}
            }).then(({ data }) => {
                setGroups(data.map(group => ({ id: group.id, name: group.group_name })));
            });
        }
    }, [selectedRole, dataProvider]);

    useEffect(() => {
        if (selectedGroup && selectedRole && selectedRole.level > 1) { 
            // Cargar instalaciones basadas en el grupo seleccionado
            dataProvider.getList('installations', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'installation_name', order: 'ASC' },
                filter: { group_id: selectedGroup } 
            }).then(({ data }) => {
                setInstallations(data.map(installation => ({ id: installation.id, name: installation.installation_name })));
            });
        }
    }, [selectedGroup, selectedRole, dataProvider]);

    const handleRoleChange = (event) => {
        const roleId = event.target.value;
        const role = roles.find(r => r.id === roleId);
        setSelectedRole(role);
        setUserGroups([]);
        setUserInstallations([]);
        setSelectedGroup('');
        setSelectedInstallation('');
    };

    const handleAddGroup = () => {
        const groupName = groups.find(group => group.id === selectedGroup)?.name;
        if (selectedGroup && groupName && !userGroups.includes(groupName)) {
            setUserGroups([...userGroups, groupName]);
            setSelectedGroup('');
        }
    };

    const handleAddInstallation = () => {
        const selectedInstallationObj = installations.find(installation => installation.id === selectedInstallation);
        if (selectedInstallationObj && !userInstallations.some(installation => installation === selectedInstallationObj.name)) {
            setUserInstallations([...userInstallations, selectedInstallationObj.name]);
            setSelectedInstallation('');
        }
    };

    const handleDeleteGroup = (groupToDelete) => () => {
        setUserGroups(userGroups.filter(group => group !== groupToDelete));
    };

    const handleDeleteInstallation = (installationToDelete) => () => {
        setUserInstallations(userInstallations.filter(installation => installation !== installationToDelete));
    };

    const handleSubmit = async (data) => {
        if (!validatePassword(data.password)) {
            alert(translate("resources.users.change_password_message"));
            return;
        }

        const updatedData = {
            ...data,
            groups: userGroups,
            installations: userInstallations,
        };

        try {
            await dataProvider.create('users', { data: updatedData });
            redirect('/users');
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ padding: 3, height: '100%' }}>
                            <Typography variant="h5" gutterBottom>{translate("resources.users.sections.user_info")}</Typography>
                            <Divider sx={{ marginBottom: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextInput source="username" label={translate("resources.users.fields.user_name")} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput source="password" type="password" label={translate("resources.users.fields.password")} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <SelectInput 
                                        source="role" 
                                        choices={roles} 
                                        onChange={handleRoleChange}
                                        label={translate("resources.users.fields.role_name")}
                                    />
                                </Grid>
                                <Typography variant="body2" sx={{ textAlign: 'justify', color:'#009000' }}> *  {translate("resources.users.change_password_message")} </Typography>
                            </Grid>
                        </Paper>
                    </Grid>

                    {selectedRole && selectedRole.level > 0 && ( 
                        <Grid item xs={12} md={6}>
                            <Paper elevation={3} sx={{ padding: 3, height: '100%' }}>
                                <Typography variant="h5" gutterBottom>
                                    {selectedRole && selectedRole.level === 1 
                                        ? translate("resources.users.sections.group_management")
                                        : translate("resources.users.sections.installation_management")
                                    }
                                </Typography>
                                <Divider sx={{ marginBottom: 2 }} />
                                {selectedRole && selectedRole.level === 1 && (
                                    <Box mt={2}>
                                        <SelectInput
                                            source="groups"
                                            choices={groups}
                                            value={selectedGroup}
                                            onChange={(e) => setSelectedGroup(e.target.value)}
                                            sx={{ width: '80%' }}
                                            label={translate("resources.users.fields.groups")}
                                        />
                                        <Button
                                            onClick={handleAddGroup}
                                            startIcon={<AddIcon />}
                                            variant="contained"
                                            color="primary"
                                            sx={{ ml: 1, mt: 2 }}
                                            disabled={!selectedGroup}
                                        >
                                            {translate("resources.users.actions.add")}
                                        </Button>
                                        <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                                            {userGroups.map((group, index) => (
                                                <Chip
                                                    key={index}
                                                    label={group}
                                                    onDelete={handleDeleteGroup(group)}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                                {selectedRole && selectedRole.level > 1 && (
                                    <Box mt={2}>
                                        <SelectInput
                                            source="groups"
                                            choices={groups}
                                            value={selectedGroup}
                                            onChange={(e) => setSelectedGroup(e.target.value)}
                                            sx={{ width: '80%' }}
                                            label={translate("resources.users.fields.groups")}
                                        />
                                        <Box mt={2}>
                                            <SelectInput
                                                source="installations"
                                                choices={installations}
                                                value={selectedInstallation}
                                                onChange={(e) => setSelectedInstallation(e.target.value)}
                                                sx={{ width: '80%' }}
                                                label={translate("resources.users.fields.installations")}
                                            />
                                            <Button
                                                onClick={handleAddInstallation}
                                                startIcon={<AddIcon />}
                                                variant="contained"
                                                color="primary"
                                                sx={{ ml: 1, mt: 2 }}
                                                disabled={!selectedInstallation}
                                            >
                                                {translate("resources.users.actions.add")}
                                            </Button>
                                            <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                                                {userInstallations.map((installation, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={installation}
                                                        onDelete={handleDeleteInstallation(installation)}
                                                        color="primary"
                                                        variant="outlined"
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;



